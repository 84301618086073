import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';
import { MODULE_SETTINGS_STORE } from '@/store/common/storeConstants';
import { fetchModuleSettingsService } from '@/services/application-service/moduleRequests';

export const useModuleSettingsStore = defineStore(MODULE_SETTINGS_STORE, () => {
    /** @type {import('@/store/types/moduleSettingsStore').ModuleSettings} */
    const moduleSettings = ref({});

    /**
     * @param {string} moduleDeploymentId 
     */
    const fetchModuleSettings = async (moduleDeploymentId) => {
        const { data: { data } } = await fetchModuleSettingsService(moduleDeploymentId);
        moduleSettings.value = data || {};
    };

    return {
        moduleSettings,
        fetchModuleSettings
    };
});
