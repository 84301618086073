// @ts-check
import { createHttpServiceConfig } from '@/services/httpFactories';
import { APPLICATION_SERVICE_DOMAIN, APPLICATION_RESOURCE_PATH, MODULE_RESOURCE_PATH, MODULE_TRANSLATIONS_RESOURCE_PATH } from './applicationServiceConstants';

export const moduleServiceConfig = {
    ...createHttpServiceConfig(APPLICATION_SERVICE_DOMAIN, APPLICATION_RESOURCE_PATH),
    /**
     * @param {string} applicationId
     * @param {string} moduleId
     * @param {string} [languageId] 
     */
    generateResourceUrl: (applicationId, moduleId, languageId = '') => {
        let url = `/${applicationId}${MODULE_RESOURCE_PATH}/${moduleId}`;
        if (languageId) {
            url += `${MODULE_TRANSLATIONS_RESOURCE_PATH}/${languageId}`;
        }
        return url;
    }
};

/**
 * @param {string} applicationId
 * @param {string} moduleId
 * @param {string} languageId
 * @param {import('./applicationService').IFetchTranslationServiceParams} params
 * @returns {import('./applicationService').FetchTranslationsResponse}
 */
export const fetchTranslationService = (applicationId, moduleId, languageId, params) => moduleServiceConfig.http({
    url: moduleServiceConfig.generateResourceUrl(applicationId, moduleId, languageId),
    params
});

/**
 * @param {string} moduleDeploymentId
 * @returns {import('./applicationService').FetchModuleSettingsResponse}
 */
export const fetchModuleSettingsService = (moduleDeploymentId) => moduleServiceConfig.http({
    url: `${MODULE_RESOURCE_PATH}/${moduleDeploymentId}/settings`
});
